<template>
    <v-container fluid>
        <v-form @submit.prevent="reload">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" @click="addFolder(null, [])"
                   color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-card flat light>
                <v-card-text class="pa-0">
                    <v-row>
                        <v-col cols="12" sm="10">
                            <v-subheader class="headline">{{ $t('search_folder') }}</v-subheader>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                            <v-btn @click="addFolder(null, [])" dark color="indigo">
                                {{ $t('create') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0" cols="12">
                            <v-text-field v-model="folder" type="text" :disabled="loading"
                                          prepend-icon="mdi-folder-text-outline"
                                          :label="$t('folder_name')" color="primary" clearable>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
        <v-card outlined class="mt-4 mb-4 elevation-0">
            <el-tree v-if="showTree" ref="tree" :data="folderItems" node-key="id" draggable
                     icon-class="mdi mdi-chevron-right" :filter-node-method="filterNode"
                     :props="defaultProps" class="categories-tree elevation-1"
                     :empty-text="$t('loading_please_wait')" :expand-on-click-node="false"
                     @node-drop="handleDrop">
                <span slot-scope="{ node, data }"
                      class="custom-tree-node container_tree align-center">
                    <v-row no-gutters>
                        <v-col cols="1" class="flex-grow-1 flex-shrink-0 text-truncate align-center"
                               style="min-width: 10px; max-width: 100%; line-height: 40px;">
                            <span @click="showFiles(data)" :class="'ml-2' + (data.is_hidden ? ' grey--text text--darken-0' : '')">
                                {{ data.name }} ({{ data.count_products }})
                            </span>
                        </v-col>
                        <v-col cols="2" style="min-width: 10px;"
                               class="flex-grow-0 flex-shrink-1 text-right">
                            <v-speed-dial v-if="$vuetify.breakpoint.xsOnly" v-model="data.fab"
                                          direction="left" transition="scale-transition">
                                <template v-slot:activator>
                                    <v-btn v-model="data.fab" light fab small icon color="gray darken-3">
                                        <v-icon v-if="data.fab" @click="tooltipShowMobile = false">mdi-close</v-icon>
                                        <v-icon v-else
                                                @click="tooltipShowMobile = true">mdi-dots-vertical-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-btn fab small dark color="red"
                                       @click.stop="deleteFolder(node, data)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn fab small dark color="green"
                                       @click.stop="editFolder(node, data)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn fab small dark color="indigo"
                                       @click.stop="addFolder(node, data)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-speed-dial>
                            <v-tooltip nudge-bottom="16" eager left>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="$vuetify.breakpoint.smAndUp" icon v-on="on"
                                           @click.stop="addFolder(node, data)">
                                        <v-icon>mdi-folder-plus-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('add') }}</span>
                            </v-tooltip>
                            <v-tooltip nudge-bottom="16" eager left>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="$vuetify.breakpoint.smAndUp" icon v-on="on"
                                           @click.stop="editFolder(node, data)">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('edit') }}</span>
                            </v-tooltip>
                            <v-tooltip nudge-bottom="16" eager left>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="$vuetify.breakpoint.smAndUp" icon v-on="on"
                                           @click.stop="deleteFolder(node, data)">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ data.deleted ? $t('remotely') : $t('delete') }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </span>
            </el-tree>
        </v-card>
        <v-dialog v-if="folderData" v-model="dialogFolder" max-width="800" persistent eager>
            <v-card>
                <ValidationObserver ref="observerForm" v-slot="{ invalid, validated, passes, validate }">
                    <v-card-title>
                        <span class="headline">
                            {{ folderData.id ? $t('folder_editing') : $t('folder_creation') }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center" cols="12" sm="4">
                                <v-card outlined elevation="3">
                                    <v-card-text class="pa-2">
                                        <v-row>
                                            <v-col cols="12" class="text-center">
                                                <v-img :src="folderData.image ? folderData.image : defaultImage"
                                                       aspect-ratio="1" contain>
                                                </v-img>
                                            </v-col>
                                            <v-col cols="12" class="pt-0 text-center">
                                                <v-row justify="center">
                                                    <v-col v-if="!folderData.image" class="text-center">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon v-on="on" @click="addImageFolder">
                                                                    mdi-image-plus
                                                                </v-icon>
                                                            </template>
                                                            <span>{{$t('add')}}</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col v-if="folderData.image" class="text-center">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon v-on="on" @click="removeImageFolder">
                                                                    mdi-delete
                                                                </v-icon>
                                                            </template>
                                                            <span>{{$t('delete')}}</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                                <input ref="uploadImageFolder" class="d-none" type="file" :show-size="1024"
                                       accept=".svg, .png, .jpg, .jpeg" @change="onFileChangedImageFolder">
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-row>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="folder" rules="required|min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="folderData.name" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-folder-text-outline"
                                                          :label="$t('folder_name')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <v-switch v-model="folderData.is_hidden" :disabled="loading"
                                                  :label="$t('folder_is_hidden')" color="primary">
                                        </v-switch>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="gray darken-1" class="ml-1 ml-sm-2" text @click="closeDialogFolder">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text :loading="loadingSaveFolder"
                               :disabled="invalid || loadingSaveFolder" class="mr-1 mr-sm-2"
                               @click="saveFolder(folderData)">
                            {{ $t('save') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"
import debounce from "lodash/debounce";

export default {
    name: "Folders",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            fab: false,
            tooltipShowMobile: false,
            tooltipShow: false,
            showTree: true,
            folder: null,
            loading: false,
            loadingSaveFolder: false,
            showPanel: {},
            dialogFolder: false,
            folderData: [],
            shopType: null,
            shopTypeItems: [],
            folderItems: [],
            addFolderData: {},
            defaultProps: {
                children: 'children',
                label: 'name',
                isLeaf: 'leaf'
            },
            node: {},
            nodedata: {}
        }
    },
    computed: {
        ...mapGetters(['lang', 'defaultImage']),
    },
    watch: {
        folder: debounce(function (val) {
            if (val) {
                this.$refs.tree.filter(val);
            }
        }, 1000),
    },
    async mounted() {
        await this.getCategories()
    },
    methods: {
        showFiles(item){
           console.log(item)
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        async handleDrop(draggingNode, dropNode, dropType, ev) {
            if (dropType === 'inner') {
                let notesInner = []
                draggingNode.data.parent_id = dropNode.data.id
                draggingNode.data.sort = (Number((dropNode.data.children.length - 1)) + 1)
                notesInner.push(draggingNode.data)
                await this.sortingCategories(dropNode.data.id, notesInner)
            }
            let parent = 0
            if (dropNode.parent.data.id !== undefined) {
                parent = dropNode.parent.data.id
            }
            let nodesPatrent = dropNode.parent.getChildren()
            for (let i in nodesPatrent) {
                nodesPatrent[i].parent_id = parent
                nodesPatrent[i].sort = (Number(i) + 1)
            }
            await this.sortingCategories(parent, nodesPatrent)
        },
        async reload() {
            await this.getCategories()
        },
        async onFileChangedImageFolder(e) {
            if (e.target.files[0]) {
                this.folderData.image = await this.convertBlobToBase64(e.target.files[0])
                this.$refs.uploadImageFolder.value = null
            }
        },
        addImageFolder() {
            this.$refs.uploadImageFolder.click()
        },
        removeImageFolder() {
            this.folderData.image = null
        },
        editFolder(node, data) {
            this.node = node
            this.nodedata = data
            this.dialogFolder = true
            this.folderData = Object.assign({}, data)
        },
        closeDialogFolder() {
            this.dialogFolder = false
            this.$nextTick(() => {
                this.folderData = {}
            })
        },
        addFolder(node, data) {
            this.dialogFolder = true
            this.node = node
            this.nodedata = data
            this.folderData = {
                id: 0,
                parent_id: (data && data.id) ? data.id : 0,
                shop_type_id: (data && data.shop_type_id) ? data.shop_type_id : this.shopType,
                name: null,
                is_hidden: false,
                image: null,
                count_products: 0,
                sort: (data && data.children && data.children !== null) ? (data.children.length + 1) : 1,
                leaf: true,
                children: false
            }
        },
        async getCategories(parent) {
            this.loading = true
            let params = {}
            params.filter = 'all'

            if (this.folder) {
                params.folder = this.folder
            }
            if (parent !== null && parent >= 0) {
                params.parent = parent
            }
            await this.$http
                .get("admin/folder", {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.length > 0) {
                        this.folderItems = res.body.data
                    } else {
                        this.folderItems = []
                    }
                })
                .catch(err => {
                    this.folderItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_folders'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteFolder(node, data) {
            if (confirm(this.$t('delete_folder'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/folder/${data.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_deleted'))
                        this.$refs.tree.remove(data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async saveFolder(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveFolder = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (item.name) {
                formData.append('folder', item.name)
            }
            if (item.is_hidden) {
                formData.append('hidden', 1)
            } else {
                formData.append('hidden', 0)
            }
            if (item.parent_id) {
                formData.append('parent', item.parent_id)
            }
            if (item.sort >= 0) {
                if (this.node === null) {
                    item.sort = (Number(this.folderItems.length) + 1)
                }
                formData.append('sort', item.sort)
            }
            if (item.image) {
                if (item.image.length > 250) {
                    var mimeType = this.getMimeType(item.image)
                    var blob = this.dataURL64toBlob(item.image)
                    if (mimeType && blob) {
                        formData.append('image', blob, mimeType)
                    }
                } else {
                    formData.append('image', item.image)
                }
            }
            if (item.id) {
                // Save
                await this.$http
                    .put(`admin/folder/${item.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations

                            this.nodedata.name = item.name
                            this.nodedata.image = item.image ? item.image : null
                            this.nodedata.is_hidden = item.is_hidden ? item.is_hidden : null
                        }

                        this.dialogFolder  = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveFolder = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/folder', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('folder_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations

                            if (!this.nodedata.children) {
                                this.$set(this.nodedata, 'children', []);
                            }

                            item.id = res.body.data.id

                            if (this.node === null) {
                                this.folderItems.push(item)
                            } else {
                                this.nodedata.children.push(item)
                            }
                        }
                        this.dialogFolder = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveFolder = false
                    })
            }
        },
        async sortingCategories(parent, nodes) {

            if (parent >= 0 && nodes.length > 0) {

                this.loading = true
                var formData = new FormData()

                if (parent > 0) {
                    formData.append('parent', parent)
                }

                for (let i in nodes) {
                    formData.append(`nodes[${i}][id]`, nodes[i].id)
                    formData.append(`nodes[${i}][sort]`, nodes[i].sort)
                }

                await this.$http
                    .post('admin/folder/sort', formData)
                    .then(res => {
                        this.$toastr.success(this.$t('folder_sorting_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('folder_sorting_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
